import React from 'react';

function HeaderComp () {
    return (
        <>
            <div className='FontsStyle'>
                <div>
                    <img src='https://cdn.venuemonk.com/corporate-party-banner.webp' alt='venuemonk' className='bg' />
                </div>
                <div style={{ paddingTop: '5px' }}>
                        <div style={{ fontSize:'min(6vw, 36px)'}}> Corporate Party </div>
                         <div style={{ paddingBottom: '15', fontSize:'min(4vw, 20px)' }}>It’s that time of the year to meet <br /> and dance with your colleagues!
                        </div>
                </div>
         </div>

        </>
    );
  }
  
  export default HeaderComp;

  
    